import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Box, Flex, Grid, Image, Text } from "theme-ui";
import OpenTeaser from "../components/page-blocks/open-teaser";
import colinProfile from "../images/colin-profile.png";
import colinSignature from "../images/colin-signature.png";

const ColinIntro = () => {
  const data = useStaticQuery(graphql`
    query colinIntroQuery {
      image: file(relativePath: { eq: "welcome-graphic-square.jpeg" }) {
        c: childImageSharp {
          f: fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <>
      <OpenTeaser halfSizeImage srcSet={data.image.c.f.srcSet}>
        <Box
          sx={{
            pb: [4],
            "& > p": { mb: [3, 4] },
          }}
        >
          <Text
            as="p"
            sx={{ fontWeight: "bold", fontSize: [3, 4] }}
            variant="paragraph"
          >
            Welcome to AQi.
          </Text>
          <Text as="p" variant="paragraph">
            We at AQA believe that educational assessment must meet the demands
            of economic and social change, driven in part by rapidly changing
            technologies, while maintaining reliability and public trust.
          </Text>
          <Text as="p" variant="paragraph">
            Navigating this complex environment requires high-quality analysis
            of the best evidence, and being open to testing diverse ideas and
            perspectives.
          </Text>
          <Text as="p" variant="paragraph">
            As an education charity, we have created AQi to bring together
            research and insight, argument and ideas, on assessment and
            qualifications - because we are acutely aware that high quality
            curriculum delivery is intimately bound up with excellent
            assessment.
          </Text>
          <Text as="p" variant="paragraph">
            AQi’s aim is to advance understanding of assessment, and to inform
            discussion and debate. We want to link research and its application
            to the real world, in the interests of students and teachers alike.
          </Text>
          <Flex sx={{ alignSelf: "flex-end" }}>
            <Image
              alt="AQA CEO Colin Hughes Profile Picture"
              sx={{
                borderRadius: "100%",
                width: 80,
                height: 80,
                alignSelf: "flex-end",
              }}
              src={colinProfile}
            />
            <Grid columns={1} gap={1} sx={{ ml: [3, 4] }}>
              <Image
                alt="AQA CEO Colin Hughes Profile Signature"
                src={colinSignature}
                sx={{ display: "block", maxWidth: 110 }}
              />
              <Text as="p" variant="paragraph" sx={{ fontWeight: "bold" }}>
                Colin Hughes
              </Text>
              <Text as="p" variant="paragraph">
                CEO, AQA
              </Text>
            </Grid>
          </Flex>
        </Box>
      </OpenTeaser>
      {/* <ColumnContainer>
      <Box
        sx={{
          bg: "offWhite",
          borderRadius: 30,
          p: [3, 4],
          py: [4],
          "& > p": { mb: [3, 4] },
        }}
      >
        <Text
          as="p"
          sx={{ fontWeight: "bold", fontSize: [3, 4] }}
          variant="paragraph"
        >
          Welcome to AQi.
        </Text>
        <Text as="p" variant="paragraph">
          Economic, technological and social change are the defining
          characteristics of the modern world.
        </Text>
        <Text as="p" variant="paragraph">
          Educational assessment must navigate this complex backdrop by drawing
          upon high-quality evidence and analysis, diverse ideas and
          perspectives.
        </Text>
        <Text as="p" variant="paragraph">
          As an education charity and assessment organisation, we have created
          AQi to bring together insight, ideas and research on assessment,
          qualifications and curriculum.
        </Text>
        <Text as="p" variant="paragraph">
          We want AQi to inform and progress discussion and debate.
        </Text>

        <Text as="p" variant="paragraph">
          We hope that you will be part of it.
        </Text>
        <Flex sx={{ alignSelf: "flex-end" }}>
          <Image
            alt="AQA CEO Colin Hughes Profile Picture"
            sx={{
              borderRadius: "100%",
              width: 80,
              height: 80,
              alignSelf: "flex-end",
            }}
            src={colinProfile}
          />
          <Grid columns={1} gap={1} sx={{ ml: [3, 4] }}>
            <Image
              alt="AQA CEO Colin Hughes Profile Signature"
              src={colinSignature}
              sx={{ display: "block", maxWidth: 110 }}
            />
            <Text as="p" variant="paragraph" sx={{ fontWeight: "bold" }}>
              Colin Hughes
            </Text>
            <Text as="p" variant="paragraph">
              CEO, AQA
            </Text>
          </Grid>
        </Flex>
      </Box>
    </ColumnContainer> */}
    </>
  );
};

export default ColinIntro;
