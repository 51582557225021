import React from "react";
import { graphql } from "gatsby";
import { Grid, Text, Flex } from "theme-ui";
import Layout from "../components/layout";
import ThemeableHeader from "../components/layout/themeable-header";
import NewsletterSignup from "../components/page-blocks/newsletter-signup";
import TwitterCallToAction from "../components/page-blocks/twitter-cta";
import OpenTeaser from "../components/page-blocks/open-teaser";
import Seo from "../components/seo";
import ColinIntro from "../components/colin-intro";
import ReusableLatestContent from "../components/page-blocks/reusable-latest-content";

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="About" />
      <ThemeableHeader title={"About"} active="/about" />
      <Grid as="section" columns={1} gap={[5, 6]} sx={{ my: [5, 6] }}>
        <ColinIntro />
        <OpenTeaser srcSet={data.aboutImageOne.c.f.srcSet} mirror color="coral">
          <Flex
            sx={{
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "flex-start",
              px: [null, null, 0, 5],
            }}
          >
            <Text as="h2" variant="title">
              What is the AQi?
            </Text>
            <Text as="p" sx={{ mb: [3, 4] }} variant="paragraph">
              AQi is an online hub for insight, data and new thinking on the
              future of assessment and qualifications.{" "}
            </Text>
            <Text as="p" sx={{ mb: [3, 4] }} variant="paragraph">
              We publish blogs, discussion papers and briefings, explore data
              and convene events.
            </Text>
            <Text as="p" sx={{ mb: [3, 4] }} variant="paragraph">
              AQi’s mission is to inform, stimulate and provide a neutral
              platform for debate, bringing together different points of view on
              policy and practice.
            </Text>
            <Text as="p" sx={{ mb: [3, 4] }} variant="paragraph">
              AQi is hosted and curated by AQA, an independent educational
              charity with a hundred-year history in assessment and
              qualifications.{" "}
            </Text>
          </Flex>
        </OpenTeaser>
        <OpenTeaser srcSet={data.aboutImageTwo.c.f.srcSet}>
          <Flex
            sx={{
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "flex-start",
              px: [null, null, 0, 5],
            }}
          >
            <Text as="h2" variant="title">
              Getting in touch
            </Text>
            <Text as="p" sx={{ mb: [3, 4] }} variant="paragraph">
              We would love to hear from you particularly if you have
              interesting experiences, research or insights that you would like
              to share with us or the wider AQi community. Just email us at{" "}
              <a href="mailto:info@aqi.org.uk">info@aqi.org.uk</a>, or{" "}
              <a href="https://twitter.com/AQ_insight">follow us on Twitter.</a>
            </Text>
            <Text as="p" variant="paragraph">
              We look forward to hearing from you.
            </Text>
          </Flex>
        </OpenTeaser>
        <ReusableLatestContent />
        <TwitterCallToAction />
        <NewsletterSignup />
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  {
    aboutImageOne: file(relativePath: { eq: "hexagons-yellow.jpg" }) {
      c: childImageSharp {
        f: fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    aboutImageTwo: file(relativePath: { eq: "dots-red.jpg" }) {
      c: childImageSharp {
        f: fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default AboutPage;
